import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import * as S from "./style";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Form from "../../components/Form";
import { Parceiro } from "../../domain/AxiosRequestParceiros";
import ParceiroComponent from "../../components/ParceiroComponent";
import CarouselWrapper from "../../components/CarouselWrapper";
import { useCampaign } from "../../context/CampaignContext";
import {
  INITIAL_FORM_DATA,
  SearchFormContext,
} from "../../context/SearchFormDataContext";
import { FaqSection } from "../../components/v2/FaqSection";
import { ParceirosSection } from "../../components/v2/ParceirosSection";
import { MensalidadeSection } from "../../components/v2/MensalidadeSection";
import { BeneficiosSection } from "../../components/v2/BeneficiosSection";
import { useGlobalState } from "../../context/GlobalStateContext";

const INTERVAL = 3000;

export default function Home() {
  const { state } = useGlobalState();
  const { campaignData } = useCampaign();
  const { updateFormData } = useContext(SearchFormContext);
  const [parceiros, setParceiros] = useState<Parceiro[]>();

  useEffect(() => {
    updateFormData(INITIAL_FORM_DATA);
  }, [updateFormData]);

  useEffect(() => {
    updateFormData(INITIAL_FORM_DATA);
  }, [updateFormData]);
  const replacePlaceholders = (text: string): string => {
    return text
      .replace("{{valor_mensalidade}}", `R$ ${state.cdtPrices.valor}`)
      .replace("{{valor_clinicogeral}}", `R$ ${state.cdtPrices.clinico_geral}`)
      .replace(
        "{{valor_demaisespecialidades}}",
        `R$ ${state.cdtPrices.demais}`
      );
  };

  const renderCarousels = () =>
    campaignData.banners?.map((banner, idx) => {
      return (
        <Carousel.Item key={`banner-${idx}`}>
          <picture>
            <source srcSet={banner.img?.mobile} media="(max-width: 30em)" />
            <source srcSet={banner.img?.desktop} />
            <img src={banner.img?.desktop} alt="banner 1" />
          </picture>
          <div className="texto">
            <h1>{banner?.title}</h1>
            <p>{replacePlaceholders(banner?.subtitle)}</p>
          </div>
        </Carousel.Item>
      );
    });

  return (
    <>
      <Header />

      <S.Banner>
        <CarouselWrapper interval={INTERVAL}>
          {renderCarousels()}
        </CarouselWrapper>
      </S.Banner>

      <BeneficiosSection />

      <MensalidadeSection />

      <S.Rede>
        <h1>
          Aproveite a nossa rede
          <br />
          com <span>mais de 12.000 parceiros.</span>
        </h1>
        <ParceirosSection />
        <Form setParceiros={setParceiros} />
      </S.Rede>

      {parceiros && parceiros?.length > 0 && (
        <S.Unidades>
          <Container>
            <Row>
              {parceiros?.map((parceiro) => (
                <ParceiroComponent {...parceiro} />
              ))}
            </Row>
          </Container>
        </S.Unidades>
      )}

      <S.Perguntas>
        <div className="background-container">
          <picture>
            <source
              srcSet={campaignData.imgs.faq.bgMobile}
              media="(max-width: 80em)"
            />
            <source srcSet={campaignData.imgs.faq.bgDesktop} />
            <img src={campaignData.imgs.faq.bgDesktop} alt="banner torcida" />
          </picture>
        </div>
        <Container>
          <Row>
            <Col md="6">
              <div className="texto">
                <h1>PERGUNTAS FREQUENTES</h1>
              </div>
            </Col>
            <Col md="6">
              <FaqSection />
            </Col>
          </Row>
        </Container>
        <div className="linear-gradient-decoration" />
      </S.Perguntas>
      <Footer />
    </>
  );
}
