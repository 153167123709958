import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Parceiros from "./pages/Parceiros";
import Beneficios from "./pages/Beneficios";
import Contato from "./pages/Contato";
import { ContactFormProvider } from "./context/ContactFormDataContext";
import CampaignProvider from "./context/CampaignContext";
import "./App.css";
import { SearchFormProvider } from "./context/SearchFormDataContext";
import { useGlobalState } from "./context/GlobalStateContext";
import { useEffect } from "react";
import { getPricesCdt } from "./services/prices-cartao-todos.service";

function App() {
  const { setCdtPrices } = useGlobalState();
  useEffect(() => {
    async function PriceCdt() {
      const response = await getPricesCdt();
      setCdtPrices(response);
      console.log("cdtprices", response);
    }
    PriceCdt();
  }, []);
  return (
    <CampaignProvider>
      <SearchFormProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/parceiros" element={<Parceiros />} />
            <Route path="/beneficios" element={<Beneficios />} />
            <Route
              path="/contato"
              element={
                <ContactFormProvider>
                  <Contato />
                </ContactFormProvider>
              }
            />
          </Routes>
        </BrowserRouter>
      </SearchFormProvider>
    </CampaignProvider>
  );
}

export default App;
